import React from "react";
import { graphql } from "gatsby";
import Layout from "../../template/Layout";
import SEO from "../../template/seo";
import BreadCrumb from "../../template/BreadCrumb";
import SocialShare from "../_util/SocialShare";
import PageBuyLinks from "../_util/PageBuyLinks";
import style from "./Faq.module.scss";

export const query = graphql`
  {
    markdownRemark(frontmatter: {slug: {eq: "/faq/"}}) {
      html
      tableOfContents(pathToSlugField: "frontmatter.slug")
      frontmatter {
        slug
        description
      }
    }
  }
`;

type FAQProps = {
    data: any;
}

const FAQ: React.FC<FAQProps> = ({ data }) => {

    return (
      <>
        <SEO 
          title="Frequently Asked Questions | Prime Draft"
          description="Answers to some of the frequently asked questions about Prime Draft, a distraction free writing program."
        />
        <Layout withBar={true} contentStyle={style.faqLayout}>
          <BreadCrumb links={[{ url: "/faq/", displayText: "FAQ" }]} />
          <article className={style.faqContent}>
            <h1>Frequently Asked Questions</h1>
            <p className={style.introText}>{data.markdownRemark.frontmatter.description}</p>
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.tableOfContents }} />
            <hr/>
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
            <hr className={style.lastBreak}/>
            <SocialShare slug={data.markdownRemark.frontmatter.slug} />
            <PageBuyLinks location="FAQ" />          
          </article>
        </Layout>
      </>  
    );

}

export default FAQ;